import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["amount", "proofOfPayment", "paymentMethod"]

  initialize() {
  }

  disableFieldsDependingOnTransactionType(event) {
    // Reset everything
    this.amountTarget.removeAttribute("disabled")
    this.proofOfPaymentTarget.removeAttribute("disabled")
    this.paymentMethodTarget.removeAttribute("disabled")

    const value = event.target.value

    switch(value) {
      case "inquiry":
        this.amountTarget.setAttribute("disabled", true)
        this.proofOfPaymentTarget.setAttribute("disabled", true)
        this.paymentMethodTarget.setAttribute("disabled", true)
        break;
      case "withdraw":
        this.proofOfPaymentTarget.setAttribute("disabled", true)
        break
      default:
        // no-op
    }
  }
}
